import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {v4 as uuidv4} from 'uuid';

export interface IReverseProxyConfig {
  domainResolverUrl: string;
  reverseProxyUrl: string;
  branchIoKey: string;
  oktaUrl: string;
  oktaClientId: string;
}

export interface IDomainResolverResponse {
  domain: string;
  appWarDomain: string;
}

export class RestApiResult<T> {
  public result: T;
}

export class VersionStatusResponse {
  public senseTimeVersion: string;
}

export class LoginResponse {
  public version: string;
  public displayVersion: string;
  public bizModeParameters: any;
  public thirdPartyParameters: any;
  public isBugReportEnabled: any;
  public customerId: any;
  public dealerId: any;
  public farmPackage: any;
  public farmId: any;
  public serverStatus: any;
  public serverStatusEndDate: any;
  public user: any;
  public farmName: any;
  public polls: any;
}

export class ConfigResponse {
  public displayVersion: string;
  public user: any;
  public polls: any;
  public farmRole: any;
  public username: any;
  public firstName: any;
  public lastName: any;
  public lastLoggedInTime: any;
  public appVersion: any;
  public authorizedUrl: any;
}

export class HttpResponseResultNoBody {
  errorResponseBody: any;
  status: number;
  statusText: string;
}

export class HttpResponseResult<T> extends HttpResponseResultNoBody{
  responseBody: T;
}

export class HeaderAuthorizationDetails {
  userName: string;
  password: string;
  farmId: string;
}

export class TokenHeaderAuthorization {
  public accessToken: string;
  public farmId: string;
  public name: string;
}

export class LoginResult {
  public accessToken: string;
  public refreshToken: string;
  public eula: DocumentResult;
  public privacyPolicy: DocumentResult;
  public displayVersion: string;
  public userId: number;
}

export class DocumentResult {
  public version: number;
  public link: string;
  public documentId: string;
}

export class ExchangeTokenResult {
  public accessToken: string;
  public displayVersion: string;
  public userName: string;
}

export class AnonymousVersionDetailsResult {
  public senseTimeVersion: string;
}

export class UserMigrationStatus {
  public isUserMigrated: boolean;
  public canPostpone: boolean;
  public askAgainIn: number;
}

export class OktaUserDetails {
  public username: string;
  public password: string;
  public options: OktaUserOptions;

  public setOktaUserDetails(username: string, password: string) {
    this.username = username;
    this.password = password;
    this.options = { multiOptionalFactorEnroll: false, warnBeforePasswordExpired: false };
  }
}

interface OktaSessionTokenResponse {
  expiresAt: string;
  status: string;
  sessionToken: string;
  _embedded: OktaEmbedded;
}

interface OktaSessionTokenErrorResponse {
  errorCode: string;
  errorId: string;
}

interface OktaEmbedded {
  user: OktaClientUser;
}

interface OktaClientUser {
  id: string;
  passwordChanged: string;
}

class OktaUserOptions {
  public multiOptionalFactorEnroll: boolean;
  public warnBeforePasswordExpired: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private static readonly  configFilePath: string = `assets/config/config.json`;

  private static readonly  loginEndpoint: string = `/rest/api/auth/login`;

  private static readonly  loginV2Endpoint: string = `/rest/api/v2/auth/login`;

  private static readonly  loginV4Endpoint: string = '/rest/api/v4/auth/login';

  private static readonly  configEndpoint: string = `/rest/api/v2/auth/configuration`;

  private static readonly  statusEndpoint: string = `/rest/api/auth`;

  private static readonly anonymousAuthEndpoint : string = `/rest/api/v2/auth`;

  private static readonly ExchangeTokenEndpoint: string = '/rest/api/v4/auth/externallogin';

  private static readonly IsMigratedEndpoint: string =  '/rest/api/user/ismigrated';

  private static readonly authTokenBasedDetailsStorageKey = 'uc';

  private static readonly authDetailsStorageKey = 'ucrp';

  private static readonly documentDetailsStorageKey = 'ped';

  public reverseProxyConfig:IReverseProxyConfig;

  public appWarDomain: string;

  private readonly OktaSessionToken = 'api/v1/authn';

  private readonly OktaSessionCookie = (oktaUrl: string, oktaClientId: string, sessionToken: string, redirectUri: string) => `${oktaUrl}oauth2/v1/authorize?client_id=${oktaClientId}&prompt=none&response_type=id_token&scope=openid&state=Af0ifjslDkj&nonce=n-0S6_WzA2Mj&sessionToken=${sessionToken}&redirect_uri=${redirectUri}`;

  constructor(private httpClient: HttpClient) {

  }

  public getVersionAnonymous(farmId: string) : Promise<HttpResponseResult<AnonymousVersionDetailsResult>> {
    let result = new HttpResponseResult<AnonymousVersionDetailsResult>();
    return this.httpClient.get<RestApiResult<AnonymousVersionDetailsResult>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.anonymousAuthEndpoint, {
      headers: {
        farmId: farmId,
        'Content-Type': 'application/json'
      },
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<AnonymousVersionDetailsResult>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public async loadConfig() {
    const reverseProxyConfigResponse = await this.httpClient.get<IReverseProxyConfig>(LoginService.configFilePath).toPromise();
    if (!this.reverseProxyConfig) {
      this.reverseProxyConfig = reverseProxyConfigResponse;
    } else {
      this.reverseProxyConfig.domainResolverUrl = reverseProxyConfigResponse.domainResolverUrl;
      this.reverseProxyConfig.reverseProxyUrl = reverseProxyConfigResponse.reverseProxyUrl;
      this.reverseProxyConfig.branchIoKey = reverseProxyConfigResponse.branchIoKey;
      this.reverseProxyConfig.oktaUrl = reverseProxyConfigResponse.oktaUrl;
      this.reverseProxyConfig.oktaClientId = reverseProxyConfigResponse.oktaClientId;
    }
  }

  public async getReverseProxyDomain(farmId: string) {
    await this.httpClient.get<IDomainResolverResponse>(this.reverseProxyConfig.domainResolverUrl, {headers: { farmId: farmId }}).toPromise()
      .then((response: IDomainResolverResponse) => {
        if (response) {
          this.reverseProxyConfig.reverseProxyUrl = response.domain;
          this.appWarDomain = response.appWarDomain;
        }
      }).catch((error) => {
        console.log('Error: failed to get domain');
        return;
      });
  }

  public get getOriginUrl(): string {
    if (this.appWarDomain) {
      return 'https://' + this.appWarDomain;
    } else {
      return window.location.origin;
    }
  }

  public get getReverseProxyEnvironmentUrl() {
    return this.reverseProxyConfig.reverseProxyUrl;
  }

  public getVersionStatus(username: string, farmId: string, region: string, password: string) : Promise<HttpResponseResult<VersionStatusResponse>> {
    let result = new HttpResponseResult<VersionStatusResponse>();
    return this.httpClient.get<RestApiResult<VersionStatusResponse>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.statusEndpoint, {
      headers: {Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'Content-Type': 'application/json'},
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<VersionStatusResponse>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public performLogin(username: string, farmId: string, region: string, password: string) : Promise<HttpResponseResult<LoginResponse>> {
    let credentials = btoa(`${username}_${farmId}_${region}:${password}`);
    let result = new HttpResponseResult<LoginResponse>();
    return this.httpClient.get<RestApiResult<LoginResponse>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginEndpoint, {
      headers: {Authorization: this.encodeCredentials(username, farmId, region, password),
        farmId: farmId,
        'Content-Type': 'application/json'},
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<LoginResponse>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public performLoginV2(username: string, farmId: string, region: string, password: string) : Promise<HttpResponseResultNoBody> {
    let credentials = btoa(`${username}_${farmId}_${region}:${password}`);
    let result = new HttpResponseResultNoBody();
    return this.httpClient.get<RestApiResult<LoginResponse>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginV2Endpoint, {
      headers: {Authorization: this.encodeCredentials(username, farmId, region, password),
        farmId: farmId,
        'Content-Type': 'application/json'},
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<LoginResponse>>) => {
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public performExchangeToken(accessToken: string, farmId: string) : Promise<HttpResponseResult<ExchangeTokenResult>> {
    let tokenRequest = {
      accessToken: accessToken,
    };
    let result = new HttpResponseResult<ExchangeTokenResult>();
    return this.httpClient.post<RestApiResult<ExchangeTokenResult>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.ExchangeTokenEndpoint, tokenRequest,
      {
        headers: {farmId: farmId,
                  'Scr-Client-Correlationid': uuidv4(),
                  'Content-Type': 'application/json'},
        observe: "response"
      }).toPromise().then((response : HttpResponse<RestApiResult<ExchangeTokenResult>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public performLoginV4(username: string, farmId: string, region: string, password: string) : Promise<HttpResponseResult<LoginResult>> {
    let loginRequest = {
      username: username,
      password: password
    };
    let result = new HttpResponseResult<LoginResult>();
    return this.httpClient.post<RestApiResult<LoginResult>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginV4Endpoint, loginRequest,
      {
      headers: {Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'scr-client-correlationid': uuidv4(),
                'Content-Type': 'application/json'},
                 observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<LoginResult>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public async getOktaAccessCookieLink(userDetails: OktaUserDetails, navigationUrl: string): Promise<string> {
    const url: string = navigationUrl;
    return this.httpClient.post<OktaSessionTokenResponse>(this.reverseProxyConfig.oktaUrl + this.OktaSessionToken, userDetails).toPromise().then((response: OktaSessionTokenResponse) => {
      if (response && response.status === 'SUCCESS') {
        return this.OktaSessionCookie(this.reverseProxyConfig.oktaUrl, this.reverseProxyConfig.oktaClientId, response.sessionToken, url);
      } else {
        return null;
      }
    }).catch(error => {
      return null;
    });
  }

  public performLoadConfiguration(username: string, farmId: string, region: string, password: string) : Promise<HttpResponseResult<ConfigResponse>> {
    let credentials = btoa(`${username}_${farmId}_${region}:${password}`);
    let result = new HttpResponseResult<ConfigResponse>();
    return this.httpClient.get<RestApiResult<ConfigResponse>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.configEndpoint, {
      headers: {Authorization: this.encodeCredentials(username, farmId, region, password),
        farmId: farmId,
        'Content-Type': 'application/json'},
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<ConfigResponse>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public getIsUserMigrated(authorization: TokenHeaderAuthorization) : Promise<HttpResponseResult<UserMigrationStatus>> {
    let requestHeaders = {
      Authorization: `Bearer ${authorization.accessToken}`,
      farmId: authorization.farmId,
      'SCR-Farm-Id': authorization.farmId,
      'scr-client-correlationid': uuidv4()
    }
    let result = new HttpResponseResult<UserMigrationStatus>();
    return this.httpClient.get<RestApiResult<UserMigrationStatus>>(this.reverseProxyConfig.reverseProxyUrl + LoginService.IsMigratedEndpoint,{
      headers: requestHeaders,
      observe: "response"
    }).toPromise().then((response : HttpResponse<RestApiResult<UserMigrationStatus>>) => {
      result.responseBody = response.body.result;
      result.status = response.status;
      result.statusText = response.statusText;
      return result;
    }).catch((errorResponse: HttpErrorResponse) => {
      result.status = errorResponse.status;
      result.statusText = errorResponse.statusText;
      result.errorResponseBody = errorResponse.error;
      return result;
    });
  }

  public validateVersion(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split('.'),
      v2parts = v2.split('.');

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push("0");
      while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }

      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }

  public btoa64(data){
    var encoded = encodeURIComponent(data).replace(/%7B/g,'{')
      .replace(/%7D/g,'}')
      .replace(/%22/g,'"')
      .replace(/%3A/g,':')
      .replace(/%20/g,' ')
      .replace(/%3D/g,'=')
      .replace(/%2C/g,',')
      .replace(/%5B/g,'[')
      .replace(/%5D/g,']')
      .replace(/%2F/g,'/');
    return btoa(encoded);
  }

  public encodeCredentials(username: string, farmId: string, region: string, password: string) : string {
    return `Basic ${btoa(`${username}_${farmId}_${region}:${password}`)}`
  }

  public storeAngular7PlusClientAuthDetails(authDetails: HeaderAuthorizationDetails, rememberMe: boolean) {
    const authDetailsEncoded = window.btoa(JSON.stringify(authDetails));
    if (rememberMe) {
      window.localStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
    } else {
      window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
    }
    window.sessionStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
  }

  public removeStoredAngular7PlusClientDetails() {
    if (window.localStorage.getItem(LoginService.authTokenBasedDetailsStorageKey) != null) {
      window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
      window.sessionStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
    }
  }

  public storeAngularTokenBasedClientDetails(authDetails: HeaderAuthorizationDetails) {
    const authDetailsEncoded = window.btoa(JSON.stringify(authDetails));
    window.localStorage.removeItem(LoginService.authDetailsStorageKey);
    window.localStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
  }

  public removeStoredAngularTokenBasedClientDetails() {
    if (window.localStorage.getItem(LoginService.authTokenBasedDetailsStorageKey) != null) {
      window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
    }
    window.localStorage.removeItem(LoginService.authDetailsStorageKey);
  }

  public storeDocumentDetails(responseBody: LoginResult) {
    const documentDetails = {eula: responseBody.eula, privacyPolicy: responseBody.privacyPolicy };
    window.localStorage.setItem(LoginService.documentDetailsStorageKey, JSON.stringify(documentDetails));
  }
}



