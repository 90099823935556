var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from "@angular/common/http";
import { v4 as uuidv4 } from 'uuid';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RestApiResult = /** @class */ (function () {
    function RestApiResult() {
    }
    return RestApiResult;
}());
export { RestApiResult };
var VersionStatusResponse = /** @class */ (function () {
    function VersionStatusResponse() {
    }
    return VersionStatusResponse;
}());
export { VersionStatusResponse };
var LoginResponse = /** @class */ (function () {
    function LoginResponse() {
    }
    return LoginResponse;
}());
export { LoginResponse };
var ConfigResponse = /** @class */ (function () {
    function ConfigResponse() {
    }
    return ConfigResponse;
}());
export { ConfigResponse };
var HttpResponseResultNoBody = /** @class */ (function () {
    function HttpResponseResultNoBody() {
    }
    return HttpResponseResultNoBody;
}());
export { HttpResponseResultNoBody };
var HttpResponseResult = /** @class */ (function (_super) {
    __extends(HttpResponseResult, _super);
    function HttpResponseResult() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return HttpResponseResult;
}(HttpResponseResultNoBody));
export { HttpResponseResult };
var HeaderAuthorizationDetails = /** @class */ (function () {
    function HeaderAuthorizationDetails() {
    }
    return HeaderAuthorizationDetails;
}());
export { HeaderAuthorizationDetails };
var TokenHeaderAuthorization = /** @class */ (function () {
    function TokenHeaderAuthorization() {
    }
    return TokenHeaderAuthorization;
}());
export { TokenHeaderAuthorization };
var LoginResult = /** @class */ (function () {
    function LoginResult() {
    }
    return LoginResult;
}());
export { LoginResult };
var DocumentResult = /** @class */ (function () {
    function DocumentResult() {
    }
    return DocumentResult;
}());
export { DocumentResult };
var ExchangeTokenResult = /** @class */ (function () {
    function ExchangeTokenResult() {
    }
    return ExchangeTokenResult;
}());
export { ExchangeTokenResult };
var AnonymousVersionDetailsResult = /** @class */ (function () {
    function AnonymousVersionDetailsResult() {
    }
    return AnonymousVersionDetailsResult;
}());
export { AnonymousVersionDetailsResult };
var UserMigrationStatus = /** @class */ (function () {
    function UserMigrationStatus() {
    }
    return UserMigrationStatus;
}());
export { UserMigrationStatus };
var OktaUserDetails = /** @class */ (function () {
    function OktaUserDetails() {
    }
    OktaUserDetails.prototype.setOktaUserDetails = function (username, password) {
        this.username = username;
        this.password = password;
        this.options = { multiOptionalFactorEnroll: false, warnBeforePasswordExpired: false };
    };
    return OktaUserDetails;
}());
export { OktaUserDetails };
var OktaUserOptions = /** @class */ (function () {
    function OktaUserOptions() {
    }
    return OktaUserOptions;
}());
var LoginService = /** @class */ (function () {
    function LoginService(httpClient) {
        this.httpClient = httpClient;
        this.OktaSessionToken = 'api/v1/authn';
        this.OktaSessionCookie = function (oktaUrl, oktaClientId, sessionToken, redirectUri) { return oktaUrl + "oauth2/v1/authorize?client_id=" + oktaClientId + "&prompt=none&response_type=id_token&scope=openid&state=Af0ifjslDkj&nonce=n-0S6_WzA2Mj&sessionToken=" + sessionToken + "&redirect_uri=" + redirectUri; };
    }
    LoginService.prototype.getVersionAnonymous = function (farmId) {
        var result = new HttpResponseResult();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.anonymousAuthEndpoint, {
            headers: {
                farmId: farmId,
                'Content-Type': 'application/json'
            },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.loadConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reverseProxyConfigResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get(LoginService.configFilePath).toPromise()];
                    case 1:
                        reverseProxyConfigResponse = _a.sent();
                        if (!this.reverseProxyConfig) {
                            this.reverseProxyConfig = reverseProxyConfigResponse;
                        }
                        else {
                            this.reverseProxyConfig.domainResolverUrl = reverseProxyConfigResponse.domainResolverUrl;
                            this.reverseProxyConfig.reverseProxyUrl = reverseProxyConfigResponse.reverseProxyUrl;
                            this.reverseProxyConfig.branchIoKey = reverseProxyConfigResponse.branchIoKey;
                            this.reverseProxyConfig.oktaUrl = reverseProxyConfigResponse.oktaUrl;
                            this.reverseProxyConfig.oktaClientId = reverseProxyConfigResponse.oktaClientId;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.getReverseProxyDomain = function (farmId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get(this.reverseProxyConfig.domainResolverUrl, { headers: { farmId: farmId } }).toPromise()
                            .then(function (response) {
                            if (response) {
                                _this.reverseProxyConfig.reverseProxyUrl = response.domain;
                                _this.appWarDomain = response.appWarDomain;
                            }
                        }).catch(function (error) {
                            console.log('Error: failed to get domain');
                            return;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(LoginService.prototype, "getOriginUrl", {
        get: function () {
            if (this.appWarDomain) {
                return 'https://' + this.appWarDomain;
            }
            else {
                return window.location.origin;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginService.prototype, "getReverseProxyEnvironmentUrl", {
        get: function () {
            return this.reverseProxyConfig.reverseProxyUrl;
        },
        enumerable: true,
        configurable: true
    });
    LoginService.prototype.getVersionStatus = function (username, farmId, region, password) {
        var result = new HttpResponseResult();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.statusEndpoint, {
            headers: { Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.performLogin = function (username, farmId, region, password) {
        var credentials = btoa(username + "_" + farmId + "_" + region + ":" + password);
        var result = new HttpResponseResult();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginEndpoint, {
            headers: { Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.performLoginV2 = function (username, farmId, region, password) {
        var credentials = btoa(username + "_" + farmId + "_" + region + ":" + password);
        var result = new HttpResponseResultNoBody();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginV2Endpoint, {
            headers: { Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.performExchangeToken = function (accessToken, farmId) {
        var tokenRequest = {
            accessToken: accessToken,
        };
        var result = new HttpResponseResult();
        return this.httpClient.post(this.reverseProxyConfig.reverseProxyUrl + LoginService.ExchangeTokenEndpoint, tokenRequest, {
            headers: { farmId: farmId,
                'Scr-Client-Correlationid': uuidv4(),
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.performLoginV4 = function (username, farmId, region, password) {
        var loginRequest = {
            username: username,
            password: password
        };
        var result = new HttpResponseResult();
        return this.httpClient.post(this.reverseProxyConfig.reverseProxyUrl + LoginService.loginV4Endpoint, loginRequest, {
            headers: { Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'scr-client-correlationid': uuidv4(),
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.getOktaAccessCookieLink = function (userDetails, navigationUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                url = navigationUrl;
                return [2 /*return*/, this.httpClient.post(this.reverseProxyConfig.oktaUrl + this.OktaSessionToken, userDetails).toPromise().then(function (response) {
                        if (response && response.status === 'SUCCESS') {
                            return _this.OktaSessionCookie(_this.reverseProxyConfig.oktaUrl, _this.reverseProxyConfig.oktaClientId, response.sessionToken, url);
                        }
                        else {
                            return null;
                        }
                    }).catch(function (error) {
                        return null;
                    })];
            });
        });
    };
    LoginService.prototype.performLoadConfiguration = function (username, farmId, region, password) {
        var credentials = btoa(username + "_" + farmId + "_" + region + ":" + password);
        var result = new HttpResponseResult();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.configEndpoint, {
            headers: { Authorization: this.encodeCredentials(username, farmId, region, password),
                farmId: farmId,
                'Content-Type': 'application/json' },
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.getIsUserMigrated = function (authorization) {
        var requestHeaders = {
            Authorization: "Bearer " + authorization.accessToken,
            farmId: authorization.farmId,
            'SCR-Farm-Id': authorization.farmId,
            'scr-client-correlationid': uuidv4()
        };
        var result = new HttpResponseResult();
        return this.httpClient.get(this.reverseProxyConfig.reverseProxyUrl + LoginService.IsMigratedEndpoint, {
            headers: requestHeaders,
            observe: "response"
        }).toPromise().then(function (response) {
            result.responseBody = response.body.result;
            result.status = response.status;
            result.statusText = response.statusText;
            return result;
        }).catch(function (errorResponse) {
            result.status = errorResponse.status;
            result.statusText = errorResponse.statusText;
            result.errorResponseBody = errorResponse.error;
            return result;
        });
    };
    LoginService.prototype.validateVersion = function (v1, v2, options) {
        var lexicographical = options && options.lexicographical, zeroExtend = options && options.zeroExtend, v1parts = v1.split('.'), v2parts = v2.split('.');
        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }
        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }
        if (zeroExtend) {
            while (v1parts.length < v2parts.length)
                v1parts.push("0");
            while (v2parts.length < v1parts.length)
                v2parts.push("0");
        }
        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }
        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return 1;
            }
            if (v1parts[i] == v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }
        if (v1parts.length != v2parts.length) {
            return -1;
        }
        return 0;
    };
    LoginService.prototype.btoa64 = function (data) {
        var encoded = encodeURIComponent(data).replace(/%7B/g, '{')
            .replace(/%7D/g, '}')
            .replace(/%22/g, '"')
            .replace(/%3A/g, ':')
            .replace(/%20/g, ' ')
            .replace(/%3D/g, '=')
            .replace(/%2C/g, ',')
            .replace(/%5B/g, '[')
            .replace(/%5D/g, ']')
            .replace(/%2F/g, '/');
        return btoa(encoded);
    };
    LoginService.prototype.encodeCredentials = function (username, farmId, region, password) {
        return "Basic " + btoa(username + "_" + farmId + "_" + region + ":" + password);
    };
    LoginService.prototype.storeAngular7PlusClientAuthDetails = function (authDetails, rememberMe) {
        var authDetailsEncoded = window.btoa(JSON.stringify(authDetails));
        if (rememberMe) {
            window.localStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
        }
        else {
            window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
        }
        window.sessionStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
    };
    LoginService.prototype.removeStoredAngular7PlusClientDetails = function () {
        if (window.localStorage.getItem(LoginService.authTokenBasedDetailsStorageKey) != null) {
            window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
            window.sessionStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
        }
    };
    LoginService.prototype.storeAngularTokenBasedClientDetails = function (authDetails) {
        var authDetailsEncoded = window.btoa(JSON.stringify(authDetails));
        window.localStorage.removeItem(LoginService.authDetailsStorageKey);
        window.localStorage.setItem(LoginService.authTokenBasedDetailsStorageKey, authDetailsEncoded);
    };
    LoginService.prototype.removeStoredAngularTokenBasedClientDetails = function () {
        if (window.localStorage.getItem(LoginService.authTokenBasedDetailsStorageKey) != null) {
            window.localStorage.removeItem(LoginService.authTokenBasedDetailsStorageKey);
        }
        window.localStorage.removeItem(LoginService.authDetailsStorageKey);
    };
    LoginService.prototype.storeDocumentDetails = function (responseBody) {
        var documentDetails = { eula: responseBody.eula, privacyPolicy: responseBody.privacyPolicy };
        window.localStorage.setItem(LoginService.documentDetailsStorageKey, JSON.stringify(documentDetails));
    };
    LoginService.configFilePath = "assets/config/config.json";
    LoginService.loginEndpoint = "/rest/api/auth/login";
    LoginService.loginV2Endpoint = "/rest/api/v2/auth/login";
    LoginService.loginV4Endpoint = '/rest/api/v4/auth/login';
    LoginService.configEndpoint = "/rest/api/v2/auth/configuration";
    LoginService.statusEndpoint = "/rest/api/auth";
    LoginService.anonymousAuthEndpoint = "/rest/api/v2/auth";
    LoginService.ExchangeTokenEndpoint = '/rest/api/v4/auth/externallogin';
    LoginService.IsMigratedEndpoint = '/rest/api/user/ismigrated';
    LoginService.authTokenBasedDetailsStorageKey = 'uc';
    LoginService.authDetailsStorageKey = 'ucrp';
    LoginService.documentDetailsStorageKey = 'ped';
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
